var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "eventManagement-form-box" },
    [
      _c("head-layout", {
        attrs: {
          "head-title": _vm.headTitle,
          "head-btn-options": _vm.headBtnOptions,
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave(false, "PREPARE")
          },
          "head-saveBack": function ($event) {
            return _vm.headSave(true, "FINISHED")
          },
          "head-cancel": _vm.headCancel,
        },
      }),
      _c("div", { staticClass: "formContentBox" }, [
        _c("div", { staticClass: "formMain" }, [
          _c(
            "div",
            { staticStyle: { padding: "0 12px" } },
            [
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  attrs: {
                    model: _vm.dataForm,
                    "label-width": "150px",
                    disabled:
                      _vm.formType == "view" ||
                      _vm.dataForm.actStatus == "CONFIRMED",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "检查编号", prop: "actCode" } },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    disabled:
                                      _vm.dataForm.isAutomatic ||
                                      _vm.pageDisabled ||
                                      _vm.dataForm.id,
                                    placeholder: "请输入检查编号",
                                  },
                                  model: {
                                    value: _vm.dataForm.actCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dataForm, "actCode", $$v)
                                    },
                                    expression: "dataForm.actCode",
                                  },
                                },
                                [
                                  _c(
                                    "template",
                                    { slot: "append" },
                                    [
                                      _vm._v(
                                        "\n                    自动生成\n                    "
                                      ),
                                      _c("el-switch", {
                                        attrs: {
                                          disabled:
                                            _vm.pageDisabled || _vm.dataForm.id,
                                          "active-color": "#13ce66",
                                        },
                                        on: { change: _vm.handleSwitch },
                                        model: {
                                          value: _vm.dataForm.isAutomatic,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm,
                                              "isAutomatic",
                                              $$v
                                            )
                                          },
                                          expression: "dataForm.isAutomatic",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "检查名称", prop: "actName" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入检查名称",
                                  title: _vm.dataForm.actName,
                                },
                                model: {
                                  value: _vm.dataForm.actName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "actName", $$v)
                                  },
                                  expression: "dataForm.actName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "检查类型", prop: "checkType" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择检查类型",
                                    disabled: "",
                                  },
                                  model: {
                                    value: _vm.dataForm.checkType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dataForm,
                                        "checkType",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "dataForm.checkType",
                                  },
                                },
                                _vm._l(_vm.check_type, function (item) {
                                  return _c("el-option", {
                                    key: item.dictKey,
                                    attrs: {
                                      label: item.dictValue,
                                      value: item.dictKey,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "分类", prop: "checkClass" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  on: { change: _vm.handleClass },
                                  model: {
                                    value: _vm.dataForm.checkClass,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dataForm, "checkClass", $$v)
                                    },
                                    expression: "dataForm.checkClass",
                                  },
                                },
                                _vm._l(
                                  _vm.hd_check_class,
                                  function (item, index) {
                                    return _c(
                                      "el-radio",
                                      {
                                        key: index,
                                        attrs: { label: item.dictKey },
                                      },
                                      [_vm._v(_vm._s(item.dictValue))]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _vm.dataForm.checkClass == "1"
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: { label: "政府单位", prop: "orgName" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      maxlength: "64",
                                      title: _vm.dataForm.orgName,
                                      placeholder: "请填写政府单位",
                                    },
                                    model: {
                                      value: _vm.dataForm.orgName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.dataForm, "orgName", $$v)
                                      },
                                      expression: "dataForm.orgName",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.dataForm.checkClass == "2"
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "上级单位",
                                    prop: "orgName1",
                                  },
                                },
                                [
                                  _c("avue-input-tree", {
                                    attrs: {
                                      nodeClick: _vm.nodeClick,
                                      props: _vm.deptProps,
                                      "default-expand-all": "",
                                      placeholder: "请选择上级单位",
                                      dic: _vm.deptDic,
                                    },
                                    model: {
                                      value: _vm.dataForm.orgId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.dataForm, "orgId", $$v)
                                      },
                                      expression: "dataForm.orgId",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.dataForm.checkClass == "3"
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "目标单位",
                                    prop: "orgName1",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      title: _vm.dataForm.orgName,
                                      placeholder: "请选择目标单位",
                                    },
                                    on: {
                                      focus: function ($event) {
                                        return _vm.handleProjectOPen()
                                      },
                                    },
                                    model: {
                                      value: _vm.dataForm.orgName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.dataForm, "orgName", $$v)
                                      },
                                      expression: "dataForm.orgName",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "检查日期", prop: "checkTime" } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "datetime",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  placeholder: "请选择检查日期",
                                },
                                model: {
                                  value: _vm.dataForm.checkTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "checkTime", $$v)
                                  },
                                  expression: "dataForm.checkTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _vm.dataForm.checkClass == "1"
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "全国安全大检查",
                                    prop: "contryFlag",
                                  },
                                },
                                [
                                  _c("el-checkbox", {
                                    attrs: {
                                      "false-label": "N",
                                      "true-label": "Y",
                                    },
                                    model: {
                                      value: _vm.dataForm.contryFlag,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "contryFlag",
                                          $$v
                                        )
                                      },
                                      expression: "dataForm.contryFlag",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _vm.dataForm.contryFlag == "Y"
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "检查组别" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入检查组别" },
                                    model: {
                                      value: _vm.dataForm.checkGroup,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "checkGroup",
                                          $$v
                                        )
                                      },
                                      expression: "dataForm.checkGroup",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _vm.dataForm.contryFlag == "Y"
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "检查类别" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      maxlength: "64",
                                      placeholder:
                                        "请输入全部安全大检查的检查类别",
                                    },
                                    model: {
                                      value: _vm.dataForm.checkKind,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.dataForm, "checkKind", $$v)
                                      },
                                      expression: "dataForm.checkKind",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "备注", prop: "remark" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  autosize: { minRows: 2 },
                                  "show-word-limit": "",
                                  maxlength: "500",
                                  placeholder: "请填写备注",
                                },
                                model: {
                                  value: _vm.dataForm.remark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "remark", $$v)
                                  },
                                  expression: "dataForm.remark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("head-layout", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.dataForm.id,
            expression: "dataForm.id",
          },
        ],
        attrs: {
          "head-title": "问题清单",
          "head-btn-options":
            _vm.formType == "view" && _vm.dataForm.id ? [] : _vm.BtnOptions,
        },
        on: { "head-romve": _vm.headRomve, "head-add": _vm.headAdd },
      }),
      _c("grid-layout", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.dataForm.id,
            expression: "dataForm.id",
          },
        ],
        ref: "gridLayOut",
        attrs: {
          "table-options": _vm.tableOption,
          "table-data": _vm.tableData,
          "table-loading": _vm.tableLoading,
          "data-total": _vm.page.total,
          page: _vm.page,
        },
        on: {
          "grid-row-detail-click": _vm.rowView,
          "gird-handle-select-click": _vm.selectionChange,
          "page-current-change": _vm.getTableList,
          "page-size-change": _vm.getTableList,
        },
        scopedSlots: _vm._u([
          {
            key: "customBtn",
            fn: function ({ row }) {
              return [
                row.hdHiddenDanger.hdStatus != "prepare" ||
                _vm.formType == "view"
                  ? _c(
                      "el-button",
                      {
                        staticStyle: { margin: "0 3px" },
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.rowView(row)
                          },
                        },
                      },
                      [_vm._v("查看")]
                    )
                  : _vm._e(),
                row.hdHiddenDanger.hdStatus == "prepare" &&
                _vm.userInfo.user_id == row.createUser &&
                _vm.formType != "view"
                  ? _c(
                      "el-button",
                      {
                        staticStyle: { margin: "0 3px" },
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.rowEdit(row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    )
                  : _vm._e(),
                row.hdHiddenDanger.hdStatus == "prepare" &&
                _vm.userInfo.user_id == row.createUser &&
                _vm.formType != "view"
                  ? _c(
                      "el-button",
                      {
                        staticStyle: { margin: "0 3px" },
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.rowDel([row])
                          },
                        },
                      },
                      [_vm._v("删除")]
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c(
        "el-dialog",
        {
          attrs: { title: "项目选择", visible: _vm.deptShow1, width: "80%" },
          on: {
            "update:visible": function ($event) {
              _vm.deptShow1 = $event
            },
          },
        },
        [
          _c("DeptDialog", {
            ref: "DeptDialog",
            attrs: { deptCategory: [2, 5] },
            on: { "select-data": _vm.selectData1 },
          }),
        ],
        1
      ),
      _vm.zxhcModel
        ? _c(
            "el-dialog",
            {
              directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
              staticClass: "avue-dialog avue-dialog--top",
              attrs: {
                title: "问题登记",
                visible: _vm.zxhcModel,
                width: "60%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.zxhcModel = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "modelFormDataForm",
                  attrs: {
                    model: _vm.modelForm,
                    "label-width": "150px",
                    disabled: _vm.modelForm.disabled,
                    rules: _vm.modelFormRules,
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "隐患编号", prop: "hdCode" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: "",
                                  placeholder: "隐患编号自动生成",
                                },
                                model: {
                                  value: _vm.modelForm.hdCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.modelForm, "hdCode", $$v)
                                  },
                                  expression: "modelForm.hdCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "检查地点", prop: "location" } },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: { placeholder: "请填写检查地点" },
                                  model: {
                                    value: _vm.modelForm.location,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.modelForm, "location", $$v)
                                    },
                                    expression: "modelForm.location",
                                  },
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _c("div", {
                                      staticClass: "el-icon-map-location",
                                      staticStyle: {
                                        width: "54px",
                                        "text-align": "center",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openMap()
                                        },
                                      },
                                    }),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "隐患描述", prop: "hdDesc" } },
                            [
                              _c(
                                "div",
                                { staticClass: "flex-container" },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      autosize: { minRows: 2 },
                                      "show-word-limit": "",
                                      maxlength: "225",
                                      placeholder: "请填写隐患描述",
                                    },
                                    model: {
                                      value: _vm.modelForm.hdDesc,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.modelForm, "hdDesc", $$v)
                                      },
                                      expression: "modelForm.hdDesc",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "hdDesc-mar" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDesc()
                                            },
                                          },
                                        },
                                        [_vm._v("常见隐患")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "报告人",
                                prop: "reportUserName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请选择报告人" },
                                on: {
                                  focus: function ($event) {
                                    return _vm.handleUserModeOpen()
                                  },
                                },
                                model: {
                                  value: _vm.modelForm.reportUserName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.modelForm,
                                      "reportUserName",
                                      $$v
                                    )
                                  },
                                  expression: "modelForm.reportUserName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "报告时间", prop: "reportDate" },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "datetime",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  placeholder: "请选择报告时间",
                                },
                                model: {
                                  value: _vm.modelForm.reportDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.modelForm, "reportDate", $$v)
                                  },
                                  expression: "modelForm.reportDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "隐患现场", prop: "treeData" } },
                            [
                              _vm.formType != "view"
                                ? _c(
                                    "el-upload",
                                    {
                                      ref: "uploadRef",
                                      staticClass: "upload-demo",
                                      staticStyle: {
                                        "margin-bottom": "12px",
                                        display: "flex",
                                        "align-items": "center",
                                      },
                                      attrs: {
                                        accept: ".jpg,.mp4,.png",
                                        limit: "9",
                                        "on-exceed": _vm.handleExceed,
                                        action:
                                          "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz-thumbnail/km",
                                        "show-file-list": false,
                                        "before-upload": _vm.checkFileType,
                                        "on-success": _vm.handleAvatarSuccess,
                                        headers: _vm.headers,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "primary",
                                            icon: "el-icon-upload",
                                          },
                                        },
                                        [_vm._v("点击上传\n              ")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "content" },
                                _vm._l(_vm.treeData, function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: item.id,
                                      staticClass: "img-content",
                                      on: {
                                        click: function ($event) {
                                          return _vm.showFile(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: item.thumbnailLink,
                                          alt: "",
                                        },
                                      }),
                                      _vm.formType !== "view"
                                        ? _c("i", {
                                            staticClass:
                                              "el-icon-circle-close delete-icon",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.handleRemove(
                                                  item,
                                                  index
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "avue-dialog__footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        loading: _vm.handleBtn,
                        disabled: _vm.handleBtn,
                      },
                      on: {
                        click: function ($event) {
                          _vm.zxhcModel = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  !_vm.modelForm.disabled
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            loading: _vm.handleBtn,
                            disabled: _vm.handleBtn,
                            type: "primary",
                          },
                          on: { click: _vm.handleZxhc },
                        },
                        [_vm._v("确 定")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择位置",
            "append-to-body": "",
            visible: _vm.mapBox,
          },
          on: {
            "update:visible": function ($event) {
              _vm.mapBox = $event
            },
          },
        },
        [
          _vm.mapBox
            ? _c("GaodeMap", {
                ref: "GaodeMap",
                on: {
                  selectAddress: _vm.selectAddress,
                  getLocation: _vm.getLocation,
                },
              })
            : _vm._e(),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.mapBox = false
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) +
                      "\n      "
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleMapSave },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "人员选择",
            visible: _vm.deptShow,
            width: "80%",
            top: "8vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.deptShow = $event
            },
          },
        },
        [
          _c("UserDetpDialog", {
            ref: "UserDetpDialog",
            on: { "select-data": _vm.selectData },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
          attrs: {
            title: "附件预览",
            modal: true,
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            visible: _vm.showFileDialog,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showFileDialog = $event
            },
          },
        },
        [
          _c("iframe", {
            ref: "fileIframe",
            staticStyle: { width: "100%", height: "500px" },
            attrs: { src: _vm.fileUrl, frameborder: "0" },
          }),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.showFileDialog = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("sortSetting", { ref: "sortSetting", on: { callback: _vm.callback } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }