<template>
  <div class="eventManagement-form-box">
    <head-layout :head-title="headTitle" :head-btn-options="headBtnOptions"
                 @head-save="headSave(false,'PREPARE')"
                 @head-saveBack="headSave(true,'FINISHED')"
                 @head-cancel="headCancel"></head-layout>
    <div class="formContentBox">
      <div class="formMain">
        <div style="padding: 0 12px">
          <el-form
            ref="dataForm"
            :model="dataForm"
            label-width="150px"
            :disabled="formType == 'view' || dataForm.actStatus == 'CONFIRMED'"
            :rules="rules"
          >
            <!-- <div class="formTopic" style="margin-bottom: 12px">检查基本信息</div> -->
            <el-row>
              <el-col :span="8">
                <el-form-item label="检查编号" prop="actCode">
                  <el-input
                    v-model="dataForm.actCode"
                    :disabled="
                    dataForm.isAutomatic || pageDisabled || dataForm.id
                  "
                    placeholder="请输入检查编号"
                  >
                    <template slot="append">
                      自动生成
                      <el-switch
                        :disabled="pageDisabled || dataForm.id"
                        v-model="dataForm.isAutomatic"
                        active-color="#13ce66"
                        @change="handleSwitch"
                      >
                      </el-switch>
                    </template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="检查名称" prop="actName">
                  <el-input
                    v-model="dataForm.actName"
                    placeholder="请输入检查名称"
                    :title="dataForm.actName"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="检查类型" prop="checkType">
                  <el-select
                    v-model.number="dataForm.checkType"
                    placeholder="请选择检查类型"
                    disabled
                  >
                    <el-option
                      v-for="item in check_type"
                      :key="item.dictKey"
                      :label="item.dictValue"
                      :value="item.dictKey"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="分类" prop="checkClass">
                  <el-radio-group v-model="dataForm.checkClass" @change="handleClass">
                  <el-radio :key="index" v-for="(item,index) in hd_check_class"  :label="item.dictKey">{{item.dictValue}}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="政府单位" prop="orgName" v-if="dataForm.checkClass == '1'">
                  <el-input
                    maxlength="64"
                    v-model="dataForm.orgName"
                    :title="dataForm.orgName"
                    placeholder="请填写政府单位"
                  ></el-input>
                </el-form-item>
                <el-form-item label="上级单位" prop="orgName1" v-if="dataForm.checkClass == '2'">
                  <avue-input-tree :nodeClick="nodeClick" :props="deptProps" default-expand-all v-model="dataForm.orgId" placeholder="请选择上级单位"
                                   :dic="deptDic"  ></avue-input-tree>
                </el-form-item>
                <el-form-item label="目标单位" prop="orgName1" v-if="dataForm.checkClass == '3'">
                  <el-input
                    v-model="dataForm.orgName"
                    @focus="handleProjectOPen()"
                    :title="dataForm.orgName"
                    placeholder="请选择目标单位"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
              <el-form-item label="检查日期" prop="checkTime">
                <el-date-picker
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  v-model="dataForm.checkTime"
                  placeholder="请选择检查日期"
                ></el-date-picker>
              </el-form-item>
              </el-col>

            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="全国安全大检查" prop="contryFlag" v-if="dataForm.checkClass == '1'">
                  <el-checkbox
                    false-label="N" true-label="Y"
                    v-model="dataForm.contryFlag"
                  ></el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="检查组别"  v-if="dataForm.contryFlag == 'Y'">
                  <el-input
                    placeholder="请输入检查组别"
                    v-model="dataForm.checkGroup"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="检查类别"  v-if="dataForm.contryFlag == 'Y'">
                  <el-input
                    maxlength="64"
                    placeholder="请输入全部安全大检查的检查类别"
                    v-model="dataForm.checkKind"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="备注" prop="remark">
                  <el-input
                    v-model="dataForm.remark"
                    type="textarea"
                    :autosize="{ minRows: 2 }"
                    show-word-limit
                    maxlength="500"
                    placeholder="请填写备注"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </div>
    <head-layout v-show="dataForm.id" head-title="问题清单" :head-btn-options="formType == 'view' &&  dataForm.id ? [] : BtnOptions" @head-romve="headRomve" @head-add="headAdd"></head-layout>
    <grid-layout v-show="dataForm.id" ref="gridLayOut" :table-options="tableOption"  :table-data="tableData" :table-loading="tableLoading" @grid-row-detail-click="rowView" @gird-handle-select-click="selectionChange" :data-total="page.total" :page="page" @page-current-change="getTableList" @page-size-change="getTableList">
      <template #customBtn="{row}">
        <el-button style="margin: 0 3px" type="text" size="small" v-if="row.hdHiddenDanger.hdStatus !='prepare' || formType == 'view' " @click="rowView(row)">查看</el-button>
        <el-button style="margin: 0 3px" v-if="row.hdHiddenDanger.hdStatus =='prepare'&& userInfo.user_id == row.createUser && formType != 'view' " type="text" size="small" @click="rowEdit(row)">编辑</el-button>
        <el-button style="margin: 0 3px" v-if="row.hdHiddenDanger.hdStatus=='prepare' && userInfo.user_id == row.createUser && formType != 'view' " type="text" size="small" @click="rowDel([row])">删除</el-button>
      </template>
    </grid-layout>
    <el-dialog title="项目选择" :visible.sync="deptShow1" width="80%">
      <DeptDialog
        ref="DeptDialog"
        :deptCategory="[2, 5]"
        @select-data="selectData1"
      ></DeptDialog>
    </el-dialog>


    <el-dialog title="问题登记"
               v-dialogDrag
               v-if="zxhcModel"
               :visible.sync="zxhcModel"
               class="avue-dialog avue-dialog--top"
               width="60%">
      <el-form ref="modelFormDataForm" :model="modelForm" label-width="150px" :disabled="modelForm.disabled" :rules="modelFormRules">
        <el-row>
          <el-col :span="12" >
            <el-form-item label="隐患编号" prop="hdCode">
              <el-input v-model="modelForm.hdCode" disabled placeholder="隐患编号自动生成"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="检查地点" prop="location">
              <el-input v-model="modelForm.location" placeholder="请填写检查地点">
                <template slot="append">
                  <div class="el-icon-map-location" style="width: 54px;text-align: center" @click="openMap()"></div>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" >
            <el-form-item label="隐患描述" prop="hdDesc">
              <div class="flex-container">
              <el-input type="textarea" :autosize="{ minRows: 2}" show-word-limit v-model="modelForm.hdDesc"
                        maxlength="225" placeholder="请填写隐患描述"></el-input>
              <div class="hdDesc-mar">
                <el-button type="primary" @click="handleDesc()">常见隐患</el-button>
              </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="报告人" prop="reportUserName">
              <el-input v-model="modelForm.reportUserName" @focus="handleUserModeOpen()"
                        placeholder="请选择报告人"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="报告时间" prop="reportDate">
              <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" v-model="modelForm.reportDate"
                              placeholder="请选择报告时间"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row >
          <el-col :span="24" >
            <el-form-item label="隐患现场" prop="treeData">
              <el-upload
                v-if="formType != 'view'"
                accept=".jpg,.mp4,.png"
                style="margin-bottom: 12px;display: flex; align-items: center;"
                class="upload-demo"
                limit="9"
                :on-exceed="handleExceed"
                action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz-thumbnail/km"
                :show-file-list="false"
                :before-upload="checkFileType"
                :on-success="handleAvatarSuccess"
                ref="uploadRef"
                :headers="headers"
              >
                <el-button
                  size="small"
                  type="primary"
                  icon="el-icon-upload"
                >点击上传
                </el-button>
              </el-upload>
              <div class="content">
                <div
                  v-for="(item, index) in treeData"
                  :key="item.id"
                  class="img-content"
                  @click="showFile(item)"
                >
                  <img :src="item.thumbnailLink" alt=""/>
                  <i
                    v-if="formType !== 'view'"
                    class="el-icon-circle-close delete-icon"
                    @click.stop="handleRemove(item, index)"
                  ></i>
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="avue-dialog__footer">
        <el-button :loading="handleBtn" :disabled="handleBtn" @click="zxhcModel=false">取 消</el-button>
        <el-button v-if="!modelForm.disabled" :loading="handleBtn" :disabled="handleBtn" @click="handleZxhc" type="primary">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="选择位置" append-to-body :visible.sync="mapBox">
      <GaodeMap v-if="mapBox" ref="GaodeMap" @selectAddress="selectAddress" @getLocation="getLocation"></GaodeMap>
      <span slot="footer">
        <el-button size="small" @click="mapBox = false">
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
        <el-button size="small" type="primary" @click="handleMapSave">
          {{ $t(`cip.cmn.btn.defBtn`) }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog title="人员选择" :visible.sync="deptShow" width="80%" top="8vh">
      <UserDetpDialog ref="UserDetpDialog" @select-data="selectData"></UserDetpDialog>
    </el-dialog>
    <el-dialog
      v-dialog-drag
      title="附件预览"
      :modal="true"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="showFileDialog"
      width="70%"
    >
      <iframe
        ref="fileIframe"
        :src="fileUrl"
        frameborder="0"
        style="width: 100%; height: 500px"
      ></iframe>
      <span slot="footer">
        <el-button size="small" @click="showFileDialog = false">取消</el-button>
      </span>
    </el-dialog>
    <sortSetting ref="sortSetting" @callback="callback"></sortSetting>
  </div>
</template>
<script>
import * as API from "@/api/check/daily";
import * as ModelAPI from "@/api/without/index";
import GridLayout from "@/views/components/layout/grid-layout";

import {dateFormat} from "@/util/date";
import {mapGetters} from "vuex";
import UserDetpDialog from "@/views/components/UserDeptDialog/UserDetpDialog"
import Template from "@/views/message/template/list.vue";
import {dictionaryBiz} from "@/api/reportTasks";
import DeptDialog from "@/views/components/UserDeptDialog/DeptDialog.vue";
import GaodeMap from "@/views/system/attendance/GaodeMap.vue";
import {getToken} from "@/util/auth";
import website from "@/config/website";
import {selectParentTree} from "@/api/system/dept";
import sortSetting from "@/views/business/safetyTtandard/sortSetting/model.vue";
export default {
  components: {
    sortSetting,
    GaodeMap,
    DeptDialog,
    Template,GridLayout,
    UserDetpDialog
  },
  data() {
    return {
      deptProps: {
        label: "deptName",
        value: "id",
      },
      deptDic: [],
      treeData:[],
      hd_status:[],
      tableOption: {
        index: true,
        linklabel:'relatedDocCode',
        menuWidth: 100,
        column: [
          {label: "问题编号", prop: "relatedDocCode", align: "left", overHidden: true},
          {label: "问题描述", prop: "remark",
            formatter:(row, value, column)=>{
              return  row.hdHiddenDanger.hdDesc
            }, align: "left", overHidden: true},
          {
            label: "问题类型",
            prop: "proType",
            width: 200,
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=HD_PRO_TYPE`,
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },

            type: 'select',
            align: "center",
            overHidden: true,
          },
          {label: "上报人", prop: "reportUserName", align: "center",width: 100,
            formatter:(row, value, column)=>{
            return  row.hdHiddenDanger.reportUserName
            }, overHidden: true},
          {label: "上报人部门",
            formatter:(row, value, column)=>{
              return  row.hdHiddenDanger.reportDeptName
            }, prop: "reportDeptName", align: "left", overHidden: true},
          {label: "上报时间",width: 170,
            formatter:(row, value, column)=>{
              return  row.hdHiddenDanger.reportDate
            }, prop: "reportDate", align: "left", overHidden: true},
          {label: "状态", prop: "hdStatus", width: 80,formatter:(row, value, column)=>{
            if(this.hd_status.length == 0) return  ''
              let hdStatus=  row.hdHiddenDanger.hdStatus
              let {dictValue}=this.hd_status.filter(item=>item.dictKey == hdStatus)[0]
              return dictValue
            }, align: "center", overHidden: true},
        ],
      },

      check_type: [],
      hd_check_class: [],
      formType: '',
      handleBtn: false,
      zxhcModel: false,
      mapBox: false,
      deptShow: false,
      deptShow1: false,
      fileUrl:'',
      showFileDialog: false,
      dataForm: {
        orgName:'',
        orgId:'',
        contryFlag:'N',
        checkClass:'1',
        isAutomatic:true,
        lossDeath:0,
        lossSerious:0,
        lossMinor:0,
      },
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      rules: {
        actName: [{
          required: true,
          message: '请输入检查名称',
          trigger: 'blur'
        }],
        actCode: [{
          required: true,
          message: '请输入检查编号',
          trigger: 'blur'
        }],
        startUserName: [{
          required: true,
          message: '请选择发起人',
          trigger: 'change'
        }],
        organizationName: [{
          required: true,
          message: '请选择所属组织',
          trigger: 'change'
        }],
        checkTime: [{
          required: true,
          message: '请选择检查时间',
          trigger: 'change'
        }],
        orgName: [{
          required: true,
          message: '请输入政府单位',
          trigger: 'blur'
        }],

      },
      modelForm:{
        disabled:false,
        reportUser:'',
        reportUserName:'',
        hdDesc:'',
        treeData:[],
      },
      modelFormRules:{
        hdDesc:[{
          required: true,
          message: '请输入隐患描述',
          trigger: 'blur'
        }],
        reportUserName:[{
          required: true,
          message: '报告人不能为空',
          trigger: 'change'
        }],
        reportDate:[{
          required: true,
          message: '报告时间不能为空',
          trigger: 'change'
        }],
        treeData:[{
          required: true,
          message: '隐患现场不能为空',
          trigger: 'blur'
        }],
      },
      BtnOptions: [{
        label: "新增",
        emit: "head-add",
        type: "button",
        icon: "",
        btnOptType: 'primary',
      }, {
        label: this.$t("cip.cmn.btn.delBtn"),
        emit: "head-romve",
        type: "button",
        icon: ""
      }],
      selectionList: [],
    }
  },
  computed: {
    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    headers: function () {
      return {"Sinoma-Auth": getToken()};
    },
    headTitle() {
      if (this.check_type.length == 0) return "";
      if (!this.dataForm.checkType) return "";
      let { dictValue } = this.check_type.filter(
        (item) => item.dictKey == this.dataForm.checkType
      )[0];
      return dictValue;
    },
    pageDisabled() {
      // 如果查看 或者 提交状态则禁用
      if (this.formType == "view" || this.dataForm.actStatus == "PREPARE") {
        return true;
      } else {
        return false;
      }
    },
    ...mapGetters([
      'userInfo',
    ]),

    headBtnOptions() {
      let result = [];
      if (['add', 'edit'].includes(this.formType)) {
        result.push({
          label: "保存",
          emit: "head-save",
          type: "button",
          btnOptType: "save",
        });
        result.push({
          label: "提交并返回",
          emit: "head-saveBack",
          type: "button",
          btnOptType: "refer",
        });
      }
      result.push({
        label: "取消",
        emit: "head-cancel",
        type: "button",
        btnOptType: "cancel",
      });
      return result;
    },
  },
 async mounted() {
    let type = this.$route.query.type;
    this.formType = type;
    if (type == 'edit') {
      this.dataForm.id = this.$route.query.id;
      this.getPrjbasicinfoDetail();
      this.getTableList(this.page)
    }else if (type == 'view') {
      this.dataForm.id = this.$route.query.id;
      this.getPrjbasicinfoDetail();
      this.getTableList(this.page)
    }else {
      this.$set(this.dataForm, "organizationId", this.userInfo.dept_id);
      this.$set(this.dataForm, "organizationName", this.userInfo.dept_name);
      this.$set(this.dataForm, "startUserId", this.userInfo.user_id);
      this.$set(this.dataForm, "startUserName", this.userInfo.real_name);
      this.handleSwitch();
      this.$set(this.dataForm, "checkType", '7');
    }

   await this.getCode('check_type')
   await this.getCode('hd_check_class')
   await this.getCode('hd_status')
    this.initDeptTree()
  },
  methods: {
    handleDesc(){
      this.$refs.sortSetting.initTree()
    },
    callback(val){
      this.$set(this.modelForm,'hdDesc',val.content)
    },
    handleExceed(files, fileList){
      this.$message.warning(`当前限制选择 9 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    nodeClick(data, node, nodeComp){
      this.$set(this.dataForm,'orgName',data.deptName)
    },
    initDeptTree() {
      selectParentTree().then((result) => {
        this.deptDic = result.data.data;
      })
    },
    handleClass(val){
      this.dataForm.contryFlag='N';
      this.dataForm.orgId='';
      this.dataForm.orgName='';
    },
    handleProjectOPen() {
      this.deptShow1 = true;
    },
    selectData1(row) {
      this.deptShow1 = false;
      this.$set(this.dataForm, "orgId", row.id);
      this.$set(this.dataForm, "orgName", row.deptName);
      console.log("进行输出人员-------------------", row);
    },
    async getPageCode() {
      let code = await API.getCode().then((res) => res.data.data);
      this.$set(this.dataForm, "actCode", code);
    },
    handleSwitch() {
      if (this.dataForm.isAutomatic) {
        this.getPageCode();
      } else {
        this.dataForm.actCode = "";
      }
    },
    async getCode(code) {
      await dictionaryBiz(code).then(res => {
        this[code] = res.data.data
      })
    },
    selectData(row){
      this.deptShow=false
      this.modelForm.reportUser = row.id
      this.modelForm.reportUserName = row.realName
      console.log("进行输出人员-------------------",row)
    },
    handleUserModeOpen(){
      this.deptShow=true
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    // 保存
    async headSave(boolean,accStatus) {
     let valid= await this.$refs.dataForm.validate().catch((err)=>{return false});
      if (!valid) return
      if(accStatus == 'FINISHED'){
        if(this.tableData.length==0){
          this.$confirm('问题清单为空，确认提交？', {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          })
            .then(() => {
              this.saveData(boolean,accStatus)
            })
        }else {
          this.saveData(boolean,accStatus)
        }
      }else {
        this.saveData(boolean,accStatus)
      }

    },
    saveData(boolean,accStatus){
      this.$loading();
      API.submit({
        ...this.dataForm,
        actStatus:accStatus ,
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: !boolean ?"保存成功" :'提交成功',
            type: "success",
          });
          if (boolean) {
            this.$router.$avueRouter.closeTag();
            this.$router.back();
          }else {
            this.dataForm.id=res.data.data
            this.getPrjbasicinfoDetail()
          }
          this.$loading().close();
        }
      }).catch(err=>{
        this.$loading().close();
      })
    },
    // 根据ID查表单详情
    getPrjbasicinfoDetail() {
      API.detail({
        id: this.dataForm.id
      }).then((res) => {
        if (res.data.code == 200) {
          this.dataForm = res.data.data;
        }
      })
    },
    // 选择列表数据
    selectionChange(list) {
      this.selectionList = list;
    },
    getTableList(page){
      this.page = page;
      this.tableLoading = true;
      ModelAPI.getList({
        actId:this.dataForm.id,
        current:this.page.currentPage,
        size:this.page.pageSize,
      }).then(res=>{
        this.tableLoading = false;
        this.tableData = res.data.data.records;
        this.page.total = res.data.data.total;
        this.$refs.gridLayOut.page.total = res.data.data.total;
      })
    },
    headAdd(){
      this.treeData=[]
      this.zxhcModel = true
      this.modelForm={}
    },
    headRomve() {
      this.rowDel(this.selectionList)
    },
    async rowView(row){
      this.$router.push({
        path: `/hiddenTrouble/riskitemView/checklist/edit`,
        query: {
          id: row.hdHiddenDanger.id,
          type: 'view',
        }
      })
      // await ModelAPI.detail({id:row.id}).then(res=>{
      //   let data=res.data.data
      //   this.modelForm={
      //     ...data.hdHiddenDanger,
      //     yhID:data.hdHiddenDanger.id,
      //     id: data.id,
      //     disabled:true
      //   }
      //   this.treeData =this.modelForm.hdUrl ? JSON.parse(this.modelForm.hdUrl) :[]
      //   this.$set(this.modelForm, "treeData", this.treeData);
      //   this.zxhcModel = true
      // })
    },
    async rowEdit(row){
      await ModelAPI.detail({id:row.id}).then(res=>{
        let data=res.data.data
        this.modelForm={
          ...data.hdHiddenDanger,
          yhID:data.hdHiddenDanger.id,
          id: data.id,
          disabled:false
        }
        this.treeData =this.modelForm.hdUrl ? JSON.parse(this.modelForm.hdUrl) :[]
        this.$set(this.modelForm, "treeData", this.treeData);
        this.zxhcModel = true
      })
    },
    rowDel(row) {
      this.selectionList = row
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          let formData = new FormData()
          formData.append('ids', this.ids)
          return ModelAPI.remove(formData);
        })
        .then(() => {
          this.getTableList(this.page)
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
    },
    handleZxhc() {
      this.$refs.modelFormDataForm.validate((valid) => {
        if (valid) {
          this.handleBtn = true
          ModelAPI.submit({
            ...this.modelForm,
            proType:'HD',
            actId:this.dataForm.id,
            organizationId:this.dataForm.organizationId,
            relatedDocCode:this.dataForm.relatedDocCode,
            hdHiddenDanger: {
              id:this.modelForm.yhID,
              location:this.modelForm.location,
              latitude:this.modelForm.latitude,
              reportDate:dateFormat(new Date()),
              organizationId:this.userInfo.dept_id,
              organizationName:this.userInfo.deptName,
              reportDeptId:this.userInfo.dept_id,
              reportDeptName:this.userInfo.deptName,
              reportUser:this.userInfo.user_id,
              reportUserName:this.userInfo.real_name,
              hdUrl:JSON.stringify(this.treeData),
              hdDesc: this.modelForm.hdDesc
            },
          }).then(res => {
            this.$message.success(res.data.msg)
            this.zxhcModel = false
            this.handleBtn = false
            this.getTableList(this.page)
          }).catch(err => {
            this.handleBtn = false
            this.$message.error(err)
            this.zxhcModel = false
          })
        }
      })

    },
    // 高德地图用
    selectAddress(data) {
      this.$set(this.modelForm, 'location', data.address);
      this.$set(this.modelForm, 'latitude', data.lng + ',' + data.lat);
    },
    // 高德地图用
    getLocation(data) {
      this.$set(this.modelForm, 'location', data.address);
      this.$set(this.modelForm, 'latitude', data.lng + ',' + data.lat);
    },
    handleMapSave() {
      this.mapBox = false
    },
    //获取经纬度
    openMap() {
      this.mapBox = true
    },
    handleAvatarSuccess(res, file, fileList) {
      this.treeData.push({
        extension: file.raw.type,
        id: file.response.data.attachId,
        link: file.response.data.link,
        thumbnailLink: file.response.data.thumbnailLink,
        name: file.response.data.originalName,
      });
      this.$set(this.modelForm, "treeData", this.treeData);
    },
    checkFileType(file) {
      const fileType = file.name.substring(file.name.lastIndexOf('.'));
      const isLt20M = file.size / 1024 / 1024 < 20;
      const isJPG = (fileType === '.jpg' || fileType == '.png' || fileType == '.mp4');

      if (!isJPG) {
        this.$message.warning('上传图片只能是 .jpg  .png .mp4 格式!');
      }
      if (!isLt20M) {
        this.$message.warning('上传文件大小不能超过 20MB!');
      }
      return isJPG && isLt20M;
    },
    handleRemove(item, index) {
      this.treeData.splice(index, 1);
      this.$set(this.modelForm, "treeData", this.treeData);
    },
    showFile(item) {
      this.fileUrl =
        process.env.VUE_APP_BASE_KKFILEURL + "?url=" +
        encodeURIComponent(Base64.encode(item.link));
      this.showFileDialog = true;
      //exls表格预览隐藏打印按钮
      if (process.env.NODE_ENV === "production") {
        const iframe = this.$refs.fileIframe;
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;

        // 在 iframe 内部隐藏元素
        if (iframeDocument) {
          const elementToHide = iframeDocument.getElementById("button-area");
          if (elementToHide) {
            elementToHide.style.display = "none";
          }
        }
      }
    },
  }
}

</script>
<style lang="scss" >
.eventManagement-form-box{
  background-color: #ffffff;
  height: auto !important;

  .formContentBox {
    padding: 12px;
    height: calc(100% - 76px);
  }

  .formMain {
    width: 100%;
    overflow: auto;
    height: 100%;
  }
  .step-box{
    background-color: #ffffff;
    width: 125px;
  }

  .formTopic {
    width: 100%;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid rgb(187, 187, 187);
    font-weight: 600 !important;
    font-size: 18px;
  }

  .shuttleBackBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;

    .el-tabs {
      width: 100%;
    }

    .table-box {
      width: calc(100% - 280px);
    }
  }

  .shutleBack {
    width: 33%;
    background: #ffffff;
    border: 1px solid #ebeef5;
    border-radius: 4px;
  }

  .leftRightBtn {
    margin: 211px 12px;
  }

  .shutleTitle {
    width: 100%;
    background-color: #f5f7fa;
    height: 40px;
    line-height: 40px;
    text-indent: 12px;
    border-bottom: 1px solid #ebeef5;
    font-size: 16px;
  }

  .shutContent {
    padding: 6px;
    height: 400px;
    overflow: auto;
  }

  .deptBox {
    line-height: 40px;
    color: #333333;
    border-bottom: 1px solid #ebeef5;
    font-size: 13px;
    display: flex;
  }

  .unitBox {
    width: 100%;
    display: flex;
    background-color: #f5f7fa;
    border-bottom: 1px solid #ebeef5;
  }

  .unitTitle {
    height: 40px;
    line-height: 40px;
    padding: 0 12px;
    font-size: 16px;
    cursor: pointer;
    border-right: 1px solid #ebeef5;
  }
  .content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .img-content {
      width: 188px;
      margin-bottom: 10px;
      height: 100px;
      padding: 3px;
      position: relative;
      border: 1px solid #ccc;
      border-radius: 3px;
      margin-right: 12px;
      .delete-icon {
        position: absolute;
        top: -6px;
        right: -6px;
        font-size: 16px;
        color: red;
      }
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

  }
}
</style>
